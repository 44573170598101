import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const TagPage = lazy(() => import("./pages/TagPage"));
const BlogPage = lazy(() => import("./pages/BlogPage"));
const NotFound = lazy(() => import("./pages/NotFound"));

export default function Routes({ appProps }) {
  return (
    <Suspense>
      <Switch>
        <Route path="/" exact component={LandingPage} appProps={appProps} />
        <Route path="/blog/:alias" exact component={BlogPage} appProps={appProps} />
        <Route path="/tag/:tag" exact component={TagPage} appProps={appProps} />

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}